var profileDropdown = document.getElementById('profile-dropdown');
profileDropdown && profileDropdown.addEventListener('click', function(e) {
  e.stopPropagation();
  document.getElementById('profile-dropdown-div').classList.toggle('hidden');
});

document.addEventListener('click', function(e) {
  var dropdownOptions = document.querySelectorAll('#traces-dropdown-div, #profile-dropdown-div');
  var dropdownArray = [...dropdownOptions];

  dropdownArray.forEach(ele => {
    if (!ele.classList.contains('hidden')) {
      ele.classList.add('hidden');
    }
  });
});

document.querySelector('#toggle-menu-options').addEventListener('click', function () {
  let menuOptions = document.querySelector('#menu-options');
  let icon =  document.querySelector('path');
  if (menuOptions.classList.contains('hidden')) {
    menuOptions.classList.remove('hidden');
    icon.setAttribute('d', "M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 " +
      "1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1" +
      "1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828")
  } else {
    menuOptions.classList.add('hidden');
    icon.setAttribute('d', 'M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 ' +
      '1 0 2H4a1 1 0 0 1 0-2z' )
  }
});
